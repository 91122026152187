import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { useRouter } from 'next/router'
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import classnames from 'classnames'
import { Icon } from '../../../components/icon'
import styles from './playlist-item.module.scss'
import { Info } from './info'

interface PlaylistItemProps {
  index: number
  id: string
  name: string
  type?: string
  unreadCount?: number
  ActionsPlaylist?: React.ReactNode
  isShared?: boolean
  isGlobal?: boolean
  icon?: string | string[]
  author?: string
  userName?: string
}

export const PlaylistItem: React.FC<PlaylistItemProps> = ({
  index,
  id,
  name,
  type,
  unreadCount,
  ActionsPlaylist,
  author,
  icon,
  userName,
  isShared
}): ReactElement => {
  const { i18n } = useLingui()
  const ref = useRef<null | HTMLDivElement>(null)
  const { query, push } = useRouter()
  const [active, setActive] = useState(false)

  const tooltip = useMemo(
    () =>
      ['JAMSESSION', 'COLLECTION', 'BRANDED'].includes(type || '')
        ? i18n._(t`label_public`)
        : i18n._(t`collaborative_playlist`),
    [type, i18n]
  )

  const iconCreator = useMemo(
    () =>
      !isShared
        ? undefined
        : ['JAMSESSION', 'COLLECTION', 'BRANDED'].includes(type || '')
        ? 'globe'
        : 'user-group-filled',
    [isShared, type]
  )

  const handlePlaylist = useCallback(() => {
    push(`/setlist/${id}/?reference=playlist_tab`)
  }, [id, push])

  const iconPlaylist = useMemo(() => {
    if (typeof icon === 'string') {
      return (
        <img
          width={40}
          height={40}
          alt="Playlist Icon"
          src={icon}
          className={styles.icon}
        />
      )
    }

    if (Array.isArray(icon) && icon.length > 0) {
      if (icon.length <= 3) {
        return (
          <img
            width={40}
            height={40}
            alt="Playlist Icon"
            src={icon[0]}
            className={styles.icon}
          />
        )
      }

      if (icon.length === 4) {
        return (
          <div className={styles.containerIcon}>
            <div className={styles.quadGrid}>
              {icon.map((src) => (
                <img key={src} alt="Playlist Icon" src={src} />
              ))}
            </div>
          </div>
        )
      }
    }

    return (
      <Icon
        name="music-list"
        width={24}
        height={24}
        className={classnames(styles.icon, styles['icon-playlist'])}
      />
    )
  }, [icon])

  useEffect(() => {
    setActive(query?.playlistId === id)
    if (query?.playlistId === id && ref && ref.current) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest'
      })
    }
  }, [id, name, query, ref])

  return (
    <div
      ref={ref}
      className={classnames(styles.container, {
        [styles.active]: active
      })}
    >
      <div className={styles.item}>
        <div className={classnames(styles.button)}>
          <Info
            title={name}
            active={active}
            onClick={handlePlaylist}
            unreadCount={unreadCount}
            className={styles.buttonIcon}
            id={`playlist_button_${index + 1}`}
            tooltip={tooltip}
            iconCreator={<Icon name={iconCreator} width={16} height={16} />}
            iconPlaylist={iconPlaylist}
            author={userName === author ? undefined : author}
          />
        </div>
        {ActionsPlaylist &&
          React.isValidElement(ActionsPlaylist) &&
          React.cloneElement(ActionsPlaylist as React.ReactElement<any>, {
            className: styles.more
          })}
      </div>
    </div>
  )
}
