import React from 'react'
import classnames from 'classnames'
import { Tooltip } from '../../../components/tooltip'
import styles from './info.module.scss'

interface Props {
  className?: string
  id?: string
  title: string
  active?: boolean
  iconCreator?: React.ReactNode
  tooltip?: string
  unreadCount?: number
  onClick?: (e: any) => void
  iconPlaylist: React.JSX.Element
  author?: string
}

export const Info: React.FC<Props> = ({
  className,
  id,
  title,
  iconCreator,
  active,
  tooltip,
  unreadCount = 0,
  onClick,
  iconPlaylist,
  author
}) => (
  <button
    id={id}
    type="button"
    onClick={onClick}
    className={classnames(className, styles.container)}
  >
    {iconPlaylist}

    <div className={classnames(styles.infoContainer)}>
      <p
        className={classnames(styles.title, {
          [styles.active]: active || unreadCount > 0
        })}
      >
        {title}
      </p>
      <div
        className={classnames(styles.info, {
          [styles.disabled]: !iconCreator && !author
        })}
      >
        {iconCreator && (
          <span className={styles.iconTooltip}>{iconCreator}</span>
        )}
        {iconCreator && tooltip && (
          <Tooltip className={styles.tooltip} title={tooltip} nozzle topRight />
        )}
        {author && <p className={styles.author}>{author}</p>}
      </div>
    </div>

    <div className={styles.content}>
      {unreadCount > 0 && (
        <span
          className={classnames(styles.unreadCount, {
            [styles.large]: unreadCount > 99
          })}
        >
          <p className={styles.count}>
            {unreadCount > 99 ? '99+' : unreadCount}
          </p>
        </span>
      )}
    </div>
  </button>
)
